import questionsReducer from "./questionsReducer";
import answersReducer from "./answersReducer";
import mbtiReducer from "./mbtiReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  questions: questionsReducer,
  answers: answersReducer,
  mbti: mbtiReducer
});

export default rootReducer;
