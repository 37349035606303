function Loading() {
  return (
    <div className="loading">
      <div>
        <div>L O A D I N G</div>
        <div className="dots">
          <div className="dot one">.</div>
          <div className="dot two">.</div>
          <div className="dot three">.</div>
        </div>
      </div>
    </div>
  )
}

export default Loading