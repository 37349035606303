import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import icLucidCircle from '../assets/images/ic-lucid-circle.png'
import icLucidText from '../assets/images/ic-lucid-text.png'
import bgImage from '../assets/images/last-gift-bg.png'
import client from '../module/client'
import Loading from '../components/Loading';
import { analytics } from '../config/firebase'

function LastGift() {
  analytics.logEvent('page_view', {
    page_title: 'LastGift'
  })

  const [loaded, setLoaded] = useState(false)
  const [sending, setSending] = useState(false)
  const [mailAddr, setMailAddr] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const img = new Image();
  useEffect(() => {
    img.src = bgImage;
    img.onload = handleImgOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleImgOnLoad = () => {
    setLoaded(true)
  }

  const handleMailInput = e => {
    setMailAddr(e.target.value)
  }

  const sendEmail = () => {
    analytics.logEvent('sign_up')
    setSending(true)
    client({
      method: 'post',
      url: '/send-coupon',
      data: {
        email: mailAddr
      }
    }).then(res => {
      if (res.status === 200) {
        setSending(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 2000);
      } else {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
      }
    })
  }

  const sendDiv = sending ? (
    <div className="dots">
      <div className="dot one">.</div>
      <div className="dot two">.</div>
      <div className="dot three">.</div>
    </div>
  ) : (
    <div className="send-text" onClick={sendEmail}>전송</div>
  )

  const errorDiv = error ? (
    <div className="error-popup show">
      전송에 실패하였습니다. 다시 시도해주세요.
    </div>
  ) : (
    <div className="error-popup hidden">
      전송에 실패하였습니다. 다시 시도해주세요.
    </div>
  )
  const successDiv = success ? (
    <div className="success-popup show">
      전송 완료! 메일함을 확인해주세요 💌
    </div>
  ) : (
    <div className="success-popup hidden">
      전송 완료! 메일함을 확인해주세요 💌
    </div>
  )

  const explanationText = `올 한해 여러분이 찾고 싶은 것은 무엇인가요?
    자존감, 평온함, 이해심, 용서, 생산성.
    이곳에선 무엇이든 얻을 수 있어요.
    이메일 주소를 입력하시면
    루시드 섬에서 자유롭게 헤엄칠 수 있는
    '루시드 프리미엄 한달 무료 쿠폰'을
    보내드립니다.`

  const dontworryText = `**쿠폰 이용 후에도 자동결제는 되지 않으니
    자유롭게 이용해 본 다음 결정하세요.`

  return !loaded ? <Loading /> : (
    <div className="lastgift">
      <div className="bg-image">
        <div className="img-wrapper">
          <img src={bgImage} alt="bgImage" />
        </div>
      </div>
      <div className="logo-area">
        <div className="ic-logo-circle">
          <img src={icLucidCircle} alt="lucid-logo" />
        </div>
        <div className="ic-logo-text">
          <img src={icLucidText} alt="lucid-text" />
        </div>
      </div>
      <h3>멘탈 웰니스 앱 루시드</h3>
      <h3>한달 무료이용</h3>
      <div className="mail-address-input-wrapper">
        <input
          type="text"
          className="mail-address"
          value={mailAddr}
          onChange={handleMailInput}
          placeholder="이메일을 입력해주세요."
        />
        <div className="send-btn">
          {sendDiv}
        </div>
      </div>
      <div className="gift-explanation">
        {explanationText}
      </div>
      <div className="dontworry">
        {dontworryText}
      </div>
      {errorDiv}
      {successDiv}
      <div className="link-wrapper">
        <Link
          to="/"
          className="link-to-start"
        >
          처음으로 돌아가기
        </Link>
      </div>
    </div>
  )
}

export default LastGift