import icArrowBackWhite from '../assets/images/ic-arrow-back-white.svg'
import icArrowBackBrown from '../assets/images/ic-arrow-back-brown.svg'
import icArrowBackBlue from '../assets/images/ic-arrow-back-blue.svg'
import imgJar from '../assets/images/questions/jar.png'

function shuffle(array) {
  var tmp, current, top = array.length;

  if (top) while (--top) {
    current = Math.floor(Math.random() * (top + 1));
    tmp = array[current];
    array[current] = array[top];
    array[top] = tmp;
  }

  return array;
}

const Question = ({
  page,
  question,
  setAnswer,
  setPageFn,
  submitAnswer
}) => {
  const {
    description,
    optionA,
    optionB,
    category,
    bgImage,
  } = question

  const handleOptionClick = e => {
    const answer = {
      category: category,
      score: parseInt(e.target.getAttribute('data-score'))
    }
    setAnswer(answer)
    setPageFn(page + 1)
  }

  const handleBackBtnClick = e => {
    setPageFn(page - 1)
  }

  const optionAObj = {
    score: -1,
    description: optionA
  }
  const optionBObj = {
    score: 1,
    description: optionB
  }
  const options = shuffle([optionAObj, optionBObj])
  const optionBtns = options.map(option => {
    return (
      <div
        className="option-btn"
        onClick={handleOptionClick}
        data-score={option.score}
        key={option.description}
      >
        {option.description}
      </div>
    )
  })

  let icBackArrow = null
  switch (page) {
    case 2:
      icBackArrow = <img src={icArrowBackWhite} alt="ic-arrow-back-white" />
      break;
    case 6:
      icBackArrow = <img src={icArrowBackBrown} alt="ic-arrow-back-brown" />
      break;
    case 7:
      icBackArrow = <img src={icArrowBackWhite} alt="ic-arrow-back-white" />
      break;
    case 9:
      icBackArrow = <img src={icArrowBackWhite} alt="ic-arrow-back-white" />
      break;
    case 15:
      icBackArrow = <img src={icArrowBackWhite} alt="ic-arrow-back-white" />
      break;
    default:
      icBackArrow = <img src={icArrowBackBlue} alt="ic-arrow-back-blue" />
      break;
  }

  const backBtn = (page === 1 || page === 16) ? (
    <div className="back-btn"></div>
  ) : (<div className="back-btn" onClick={handleBackBtnClick}>
    {icBackArrow}
  </div>
    )

  return (page !== 16) ? (
    <div className={`question-${page} question-wrapper`}>
      <div className="bg-image">
        <div className="img-wrapper">
          <img src={bgImage} alt="bgImage" />
        </div>
      </div>
      <div className="question-header">
        {backBtn}
      </div>
      <div className="question-description">
        {description}
      </div>
      <div className="options-wrapper">
        {optionBtns}
      </div>
    </div>
  ) : (
    <div className={`question-${page} question-wrapper`}>
      <div className="bg-image">
        <div className="img-wrapper">
          <img src={bgImage} alt="bgImage" />
        </div>
      </div>
      <div className="question-description">
        {`어항 속엔
      무엇이 들어있을까요?
      `}
      </div>
      <div className="jar">
        <div>
          <img src={imgJar} alt="jar" />
        </div>
      </div>
      <div className="submit-btn" onClick={submitAnswer}>
        나의 내면 친구 살펴보기
      </div>
    </div>
  )
}

export default Question