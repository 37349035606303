import bg1 from '../assets/images/questions/bg-1.png'
import bg2 from '../assets/images/questions/bg-2.png'
import bg3 from '../assets/images/questions/bg-3.png'
import bg4 from '../assets/images/questions/bg-4.png'
import bg5 from '../assets/images/questions/bg-5.png'
import bg6 from '../assets/images/questions/bg-6.png'
import bg7 from '../assets/images/questions/bg-7.png'
import bg8 from '../assets/images/questions/bg-8.png'
import bg9 from '../assets/images/questions/bg-9.png'
import bg10 from '../assets/images/questions/bg-10.png'
import bg11 from '../assets/images/questions/bg-11.png'
import bg12 from '../assets/images/questions/bg-12.png'
import bg13 from '../assets/images/questions/bg-13.png'

import imgYongWang from '../assets/images/img-yongwang.png'
import imgJar from '../assets/images/questions/jar.png'

export const questions = {
  1: {
    category: "Favorite World",
    description: "낯선 모험을 앞둔 지금,\n이 섬에서 나는 어떤 생각을 하고 있나요?",
    optionA: "누구 같이 갈 사람 없나?",
    optionB: "다 필요 없어. 혼자서도 충분해",
    bgImage: bg1,
    transitionImages: [],
    transitionText: null,
  },
  2: {
    category: "Favorite World",
    description: "섬에서 모험을 시작한 당신,\n지금 이 순간을 어떻게 기록하고 싶나요?",
    optionA: "찰칵, 바로 인스타 각이지",
    optionB: "슥삭슥삭, 꼼꼼히 적어놔야지",
    bgImage: bg2,
    transitionImages: [],
    transitionText: null,
  },
  3: {
    category: "Favorite World",
    description: "모험가들의 숙소 발견!\n나는 숙소에서 무엇을 할까요?",
    optionA: "이 구역의 핵인싸가 되겠어~",
    optionB: "에어팟 끼고 방으로 직행",
    bgImage: bg3,
    transitionImages: [],
    transitionText: null,
  },
  4: {
    category: "Transition",
    description: null,
    optionA: null,
    optionB: null,
    bgImage: bg4,
    transitionImages: [],
    transitionText: "아침 해가 떴습니다.\n모험을 시작해볼까요?",
  },
  5: {
    category: "Information",
    description: "본격 모험 시작!\n지금 내 마음은 어떤가요?",
    optionA: "용왕 어딨냐 난 너만 찾으면 된다",
    optionB: "이 섬, 설레는걸? 재밌는 거 없을까?",
    bgImage: bg4,
    transitionImages: [],
    transitionText: null,
  },
  6: {
    category: "Information",
    description: "1일차는 허탕,\n호텔에 쉬러\n돌아왔어요.\n내일은 어떻게\n용왕님을 찾을까요?",
    optionA: "저기 다른 분들은 어떻게 하고 계셔요..?",
    optionB: "나만의 기똥찬 방법, 떠올라라!",
    bgImage: bg5,
    transitionImages: [],
    transitionText: null,
  },
  7: {
    category: "Information",
    description: "섬에서의 둘째날 밤.\n지금 무슨 생각을 하나요?",
    optionA: "오늘을 돌아보고, 내일은 더 잘 해야지",
    optionB: "내일은 또 어떤 신나는 일이 있을까?",
    bgImage: bg6,
    transitionImages: [],
    transitionText: null,
  },
  8: {
    category: "Transition",
    description: null,
    optionA: null,
    optionB: null,
    bgImage: bg7,
    transitionImages: [],
    transitionText: "며칠이 지나도\n내가 찾는 바다 용왕님은\n만날 수가 없습니다.",
  },
  9: {
    category: "Decisions",
    description: "지금, 나에게 필요한 것은?",
    optionA: "다시 철저히 계.획.한.다.",
    optionB: "위로와 공감이 필요해",
    bgImage: bg7,
    transitionImages: [],
    transitionText: null,
  },
  10: {
    category: "Decisions",
    description: "용왕님을 찾던 중,\n힘들어 보이는 사람을 마주쳤습니다.\n나는 어떻게 할 건가요?",
    optionA: "저기요, 무슨 일이신가요?",
    optionB: "...호다닥 갈 길 가야지",
    bgImage: bg8,
    transitionImages: [],
    transitionText: null,
  },
  11: {
    category: "Decisions",
    description: "용왕 찾아 열흘 째. 더 이상은 방법이 없네요.\n바닷속으로 뛰어들기로 결심합니다.\n지금 내게 제일 필요한 것은?",
    optionA: "바다 여행 꿀팁 모음집",
    optionB: "에너지 뿜뿜! 사람들의 응원",
    bgImage: bg9,
    transitionImages: [],
    transitionText: null,
  },
  12: {
    category: "Structure",
    description: "입수 직전, 뭔가 빠뜨린 기분이 듭니다.\n이 때 나는?",
    optionA: "기분 탓이야. 시간 됐으니 간다.",
    optionB: "조금 늦더라도 다시 한 번 꼼꼼히 체크",
    bgImage: bg10,
    transitionImages: [],
    transitionText: null,
  },
  13: {
    category: "Transition",
    description: null,
    optionA: null,
    optionB: null,
    bgImage: bg11,
    transitionImages: [imgYongWang, imgJar],
    transitionText: "드디어 용왕님을 만났습니다!\n나는 용왕님이 주신 어항을 들고\n숙소로 돌아갑니다.",
  },
  14: {
    category: "Structure",
    description: "모험을 마치고 집으로 가려는데,\n일행이 하루만 더 섬에서 머물다 가자고 합니다.",
    optionA: "댓츠 노노. 목표 달성했으니 집으로 직행",
    optionB: "갈 땐 가더라도, 하루 쯤은 괜찮잖아?",
    bgImage: bg11,
    transitionImages: [],
    transitionText: null,
  },
  15: {
    category: "Structure",
    description: "집으로 돌아온 당신,\n무엇을 할까요?",
    optionA: "자기 전에 짐 정리는 필수!",
    optionB: "드르렁..푸..내일 생각하자..",
    bgImage: bg12,
    transitionImages: [],
    transitionText: null,
  },
  16: {
    category: null,
    description: null,
    optionA: null,
    optionB: null,
    bgImage: bg13,
    transitionImages: [],
    transitionText: null,
  },
}