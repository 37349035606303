import { SET_MBTI } from "../actionTypes";

const initialState = {};

const mbtiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MBTI:
      return {...state, data: action.data};

    default:
      return state;
  }
};

export default mbtiReducer;
