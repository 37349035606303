import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Howl } from 'howler'

import { analytics } from './config/firebase'
import PageView from './components/PageView';
import BGMAudioFile from './assets/sounds/bgm.mp3'

function App() {
  const location = useLocation()

  const [isPlaying, setIsPlaying] = useState(false)
  const [sound, setSound] = useState('')

  const playSound = (src) => {
    const sound = new Howl({
      src,
      html5: true
    })
    setSound(sound)
    sound.play();
  }

  const togglePlay = () => {
    if (isPlaying) {
      analytics.logEvent('stop_bgm')
      sound.stop()
      setIsPlaying(false)
    } else {
      analytics.logEvent('play_bgm')
      playSound(BGMAudioFile)
      setIsPlaying(true)
    }
  }

  const bgmText = isPlaying ? "BGM ON" : "BGM OFF"

  useEffect(() => {
    if (
      (location.pathname.includes("result") || location.pathname.includes("lastgift"))
      && isPlaying && sound
    ) {
      sound.stop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const bgmDiv = (location.pathname.includes("result") || location.pathname.includes("lastgift")) ? (
    null
  ) : (
    <div className="bgm-box" onClick={togglePlay}>
      {bgmText}
    </div>
  )

  return (
    <div className="App">
      <PageView />
      {bgmDiv}
    </div>
  );
}

export default App;
