import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Howl } from 'howler'

import icPlay from '../assets/images/ic-play.svg'
import icStop from '../assets/images/ic-stop.svg'
import icKakao from '../assets/images/results/ic-kakao.png'
import icLink from '../assets/images/results/ic-link.png'
import icLucidText from '../assets/images/ic-lucid-text.png'

import client from '../module/client'
import Loading from '../components/Loading';
import { analytics } from '../config/firebase';

function Result() {
  const location = useLocation()
  const history = useHistory()
  const mbti = (new URLSearchParams(location.search).get('innerself')).toLowerCase()
  
  const [loaded, setLoaded] = useState(false)
  const [modifier, setModifier] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [audioName, setAudioName] = useState('')
  const [audioFile, setAudioFile] = useState('')
  const [friendName, setFriendName] = useState('')
  const [friendMbti, setFriendMbti] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const [sound, setSound] = useState('')
  const [copied, setCopied] = useState(false)
  
  useEffect(() => {
    client({
      method: 'get',
      url: '/result',
      params: {
        mbti: mbti
      }
    }).then(res => {
      setModifier(res.data.modifier)
      setName(res.data.name)
      setDescription(res.data.description)
      setAudioName(res.data.audioName)
      setAudioFile(res.data.audioFile)
      setFriendName(res.data.friendName)
      setFriendMbti(res.data.friendMbti)
      setLoaded(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if (loaded) {
      window.Kakao.Link.createDefaultButton({
        container: '#hello',
        objectType: 'feed',
        content: {
          title: '용왕님이 알려주는 내면 심리 테스트',
          description: '바닷속 내 모습을 찾아서 떠나보세요.',
          imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/lucid-mbti-soulmate.appspot.com/o/og%20image.png?alt=media',
          link: {
            mobileWebUrl: `https://innerself.lucidisland.com/share?innerself=${mbti}`,
            webUrl: `https://innerself.lucidisland.com/share?innerself=${mbti}`,
          },
        },
        buttons: [
          {
            title: '결과 확인하기',
            link: {
              mobileWebUrl: `https://innerself.lucidisland.com/share?innerself=${mbti}`,
              webUrl: `https://innerself.lucidisland.com/share?innerself=${mbti}`,
            },
          },
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  const playSound = (src) => {
    const sound = new Howl({
      src,
      html5: true
    })
    setSound(sound)
    sound.play();
  }
  
  const togglePlay = () => {
    if (isPlaying) {
      analytics.logEvent('stop_recommended_audio')
      sound.stop()
      setIsPlaying(false)
    } else {
      analytics.logEvent('play_recommended_audio')
      playSound(audioFile)
      setIsPlaying(true)
    }
  }

  const logKakaoShareBtnClick = () => {
    analytics.logEvent('kakao_share')
  }

  const copyLinkToClipboard = () => {
    analytics.logEvent('copy_share_link')
    navigator.clipboard.writeText(`https://innerself.lucidisland.com/share?innerself=${mbti}`)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  const goToLastGift = () => {
    history.push("/last-gift")
  }

  const playIcon = isPlaying ? (
    <img src={icStop} className="ic-stop" alt="stop" />
  ) : (
    <img src={icPlay} className="ic-play" alt="play" />
  )

  const copiedDiv = copied ? (
    <div className="copy-popup show">
      링크가 복사되었습니다.
    </div>
  ) : (
    <div className="copy-popup hidden">
      링크가 복사되었습니다.
    </div>
  )

  return loaded ? (
    <div className={`result-wrapper ${mbti}`}>
      <div className="lucid-text-logo">
        <img src={icLucidText} alt="lucid" />
      </div>
      <div className="character-wrapper"></div>
      <div className="result-description-wrapper">
        <div className="modifier">{modifier}</div>
        <div className="name">{name}</div>
        <div className="description">{description}</div>
      </div>
      <div className="soulsound-wrapper">
        <div className="title">#용왕님 추천 오디오</div>
        <div className="sound-item" onClick={togglePlay}>
          <div className="sound-name">{audioName}</div>
          <div className="play-icon">
            {playIcon}
          </div>
        </div>
      </div>
      <div className="friend-wrapper">
        <div className="title">#나와의 찰떡궁합</div>
        <div className={`friend-img ${friendMbti}`}></div>
        <div className="friend-name">{friendName}</div>
      </div>
      <div className="last-gift" onClick={goToLastGift}>용왕님의 마지막 선물 🎁</div>
      <div className="share">
        <div className="icons">
          <img className="icon ic-kakao" id="hello" src={icKakao} alt="ic-kakao" onClick={logKakaoShareBtnClick} />
          <img className="icon ic-link" src={icLink} alt="ic-link" onClick={copyLinkToClipboard}/>
        </div>
      </div>
      {copiedDiv}
    </div>
  ) : <Loading />
}

export default Result