import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyD3s9LsSFTNrjDpJm0W_Po6RlbJKOXYKHM",
  authDomain: "lucid-mbti-soulmate.firebaseapp.com",
  databaseURL: "https://lucid-mbti-soulmate-default-rtdb.firebaseio.com",
  projectId: "lucid-mbti-soulmate",
  storageBucket: "lucid-mbti-soulmate.appspot.com",
  messagingSenderId: "708682898212",
  appId: "1:708682898212:web:c2e5aa06cdd179826cf1b1",
  measurementId: "G-6817Y7B7XN"
};

firebase.initializeApp(firebaseConfig)

export const analytics = firebase.analytics();