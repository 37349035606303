import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Question from '../components/Question';
import Transition from '../components/Transition';
import { SET_ANSWER, SET_MBTI } from '../store/actionTypes';

import { analytics } from '../config/firebase'
import client from '../module/client'
import { questions } from '../helpers/questions'
import Loading from '../components/Loading';

function Travel() {
  const history = useHistory()
  const dispatch = useDispatch()
  const answers = useSelector(state => state.answers)
  const [page, setPage] = useState(1)
  const [loaded, setLoaded] = useState(true)

  const setAnswer = answer => {
    dispatch({ type: SET_ANSWER, page: page, answer: answer })
  }

  const setPageFn = page => {
    setPage(page)
  }

  const submitAnswer = () => {
    analytics.logEvent('submit_answer')
    setLoaded(false)
    client({
      method: 'post',
      url: '/answers',
      data: {
        answers: answers
      }
    }).then(res => {
      dispatch({ type: SET_MBTI, data: res.data })
      history.push(`/result?innerself=${res.data}`)
    })
  }

  return loaded ? (
    questions[page].transitionText === null ? (
      <div className="travel">
        <Question
          page={page}
          question={questions[page]}
          setAnswer={answer => setAnswer(answer)}
          setPageFn={page => setPageFn(page)}
          submitAnswer={submitAnswer}
        />
      </div>
    ) : (
      <div className="travel">
        <Transition
          page={page}
          question={questions[page]}
          setPageFn={page => setPageFn(page)}
        />
      </div>
    )
  ) : <Loading />
}

export default Travel