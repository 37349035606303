import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Loading from '../components/Loading';

import icLucidText from '../assets/images/ic-lucid-text.png'
import icContinue from '../assets/images/ic-continue.png'
import icArrow from '../assets/images/arrow.svg'
import imgYongWang from '../assets/images/img-yongwang.png'
import imgHome from '../assets/images/img-home.png'
import imgHomeFirst from '../assets/images/img-home-first.png'
import imgCloud from '../assets/images/cloud.png'
import bg1 from '../assets/images/questions/bg-1.png'
import bg2 from '../assets/images/questions/bg-2.png'
import bg3 from '../assets/images/questions/bg-3.png'
import bg4 from '../assets/images/questions/bg-4.png'
import bg5 from '../assets/images/questions/bg-5.png'
import bg6 from '../assets/images/questions/bg-6.png'
import bg7 from '../assets/images/questions/bg-7.png'
import bg8 from '../assets/images/questions/bg-8.png'
import bg9 from '../assets/images/questions/bg-9.png'
import bg10 from '../assets/images/questions/bg-10.png'
import bg11 from '../assets/images/questions/bg-11.png'
import bg12 from '../assets/images/questions/bg-12.png'
import bg13 from '../assets/images/questions/bg-13.png'

function Home() {
  const history = useHistory()
  const [showIntro, setShowIntro] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleNextBtnClick = e => {
    setShowIntro(true)
  }

  const startTravel = e => {
    history.push('/travel')
  }

  const imgSrcs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11, bg12, bg13]

  const handleHomeBgLoad = () => {
    setLoaded(true)
  }

  useEffect(() => {
    imgSrcs.forEach(src => {
      const img = new Image()
      img.src = src;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const content = showIntro ? (
    <>
      <div className="intro-tap-to-next" onClick={startTravel}>
        <div className="img-yongwang">
          <img src={imgYongWang} alt="img-yongwang" />
        </div>
        <div className="intro-desc">
          <p>루시드 섬으로 여행을 떠난 당신.</p>
          <p>용왕님을 찾아서</p>
          <p>바닷속 내 모습을 발견할 수 있을까요?</p>
        </div>
        <div className="continue">
          <p>계속하기</p>
          <img src={icContinue} alt="ic-continue" />
        </div>
      </div>
      <div className="bg-image">
        <div className="img-wrapper">
          <img className="img-home" src={imgHome} alt="home-bg" />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="turn-bg">
        소리를 켜주세요!
        <img src={icArrow} alt="arrow" />
      </div>
      <div className="ic-logo-text">
        <img src={icLucidText} alt="lucid-text" />
      </div>
      <div className="img-cloud">
        <img src={imgCloud} alt="cloud" />
        <div className="abs-title">
          <p>용왕님이 알려주는</p>
          <p>내면 심리 테스트</p>
        </div>
      </div>
      <div className="introduction">
        <p className="gamja-text">
          <span className="blue">바닷속 내 모습</span>
          을
        </p>
        <p className="gamja-text">찾으러</p>
        <p className="gamja-text">떠나보세요</p>
      </div>
      <div className="go-to-test-btn" onClick={handleNextBtnClick} >
        시작하기
      </div>
      <div className="bg-image">
        <div className="img-wrapper">
          <img className="img-home" src={imgHomeFirst} alt="home-bg" onLoad={handleHomeBgLoad} />
        </div>
      </div>
    </>
  )

  return (
    <div className="home">
      {!loaded ? <Loading /> : null}
      {content}
    </div>
  )
}

export default Home