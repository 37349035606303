import icContinue from '../assets/images/ic-continue.png'
import icArrowBackWhite from '../assets/images/ic-arrow-back-white.svg'
import icArrowBackBlue from '../assets/images/ic-arrow-back-blue.svg'

const Transition = ({
  page,
  question,
  setPageFn,
}) => {
  const {
    bgImage,
    transitionText,
    transitionImages
  } = question

  const handleTransitionPageTab = e => {
    setPageFn(page + 1)
  }

  const handleBackBtnClick = e => {
    e.stopPropagation();
    setPageFn(page - 1)
  }

  let icBackArrow = null
  if (page === 8) {
    icBackArrow = <img src={icArrowBackWhite} alt="ic-arrow-back-white" />
  } else {
    icBackArrow = <img src={icArrowBackBlue} alt="ic-arrow-back-blue" />
  }

  const backBtn = (page === 1 || page === 16) ? (
    <div className="back-btn"></div>
  ) : (<div className="back-btn" onClick={handleBackBtnClick}>
    {icBackArrow}
  </div>
    )

  const transitionImageDivs = transitionImages.map((src, idx) => {
    return (
      <div className={`transition-image-${idx}`} key={idx}>
        <img src={src} alt={src} />
      </div>
    )
  })

  return (
    <div className={`question-${page} question-wrapper question-transition-absolute`} onClick={handleTransitionPageTab}>
      <div className="bg-image">
        <div className="img-wrapper">
        <img src={bgImage} alt="bgImage" />
        </div>
      </div>
      <div className="question-header">
        {backBtn}
      </div>
      <div className="question-transition">
        {transitionText}
      </div>
      <div className="continue">
        <p>다음으로</p>
        <img src={icContinue} alt="ic-continue" />
      </div>
      <div className="transition-images">
        {transitionImageDivs}
      </div>
    </div>
  )
}

export default Transition