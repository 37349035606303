import { SET_QUESTIONS } from "../actionTypes";

const initialState = {};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONS:
      return action.data;

    default:
      return state;
  }
};

export default questionsReducer;
