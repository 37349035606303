import React from 'react'
import { Route } from 'react-router-dom'

import Home from '../pages/Home';
import Travel from '../pages/Travel';
import Result from '../pages/Result';
import LastGift from '../pages/LastGift';
import Share from '../pages/Share';

const PageView = () => {
  return (
    <div className="pageview-wrapper" >
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/travel">
        <Travel />
      </Route>
      <Route path="/result">
        <Result />
      </Route>
      <Route path="/last-gift">
        <LastGift />
      </Route>
      <Route path="/share">
        <Share />
      </Route>
    </div>
  )
}

export default PageView;